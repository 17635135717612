import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { connect } from 'react-redux'

import api from 'services/pet-notify-api'
import { submitOrder } from 'UserApp/store/intellitagOrder/actions'
import Payment from 'UserApp/components/OrderIntellitagModal/Payment'
import useUser from 'UserApp/hooks/useUser'

function PaymentContainer(props) {
  const stripe = useStripe()
  const elements = useElements()
  const { promoCode, submitOrder, intellitagOrder, isMobile } = props
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [wasSuccessful, setWasSuccessful] = useState(false)
  const [breakdown, setBreakdown] = useState()
  const [canMakePayment, setCanMakePayment] = useState(false)
  const [isPaymentRequired, setIsPaymentRequired] = useState(true)
  const [subscriptionPlan, setSubscriptionPlan] = useState('month')
  const user = useUser()
  const paymentRequest = useRef()
  const [paymentRequestKey, setPaymentRequestKey] = useState(0)
  // console.log(`USER DATTTA ::::`,user)

  useEffect(() => {
    if(user?.user.status==='free'){
      setSubscriptionPlan('month')
    }else{
      setSubscriptionPlan('')
    }
  }, [user?.user.status])

  function handleChangeSubscriptionPlan(plan) {
    setSubscriptionPlan(plan)
  }

  useEffect(() => {
    if (stripe && intellitagOrder.intellitags) {
      setIsLoading(true)
      api.intellitagOrders
        .breakdown({ ...intellitagOrder, promoCode, subscriptionPlan })
        .then((result) => {
          const paymentRequired = result.total > 0
          setBreakdown(result)
          setIsPaymentRequired(paymentRequired)
          setIsLoading(false)

          if (isPaymentRequired) {
            const newPaymentRequest = stripe.paymentRequest({
              country: 'US',
              currency: 'usd',
              total: {
                label: 'Intellitag(s)',
                amount: result.total,
              },
              requestPayerName: true,
              requestPayerEmail: true,
            })

            newPaymentRequest.canMakePayment().then((result) => {
              if(result){
                paymentRequest.current = newPaymentRequest;
                setCanMakePayment(true)
                setPaymentRequestKey((prevKey) => prevKey + 1)
              } else {
                setCanMakePayment(false)
              }
            })

            newPaymentRequest.on('token', async ({ complete, token }) => {
              setIsProcessing(true)

              try {
                await submitOrder({
                  ...intellitagOrder,
                  token: token.id,
                  promoCode,
                })
                setWasSuccessful(true)
                complete('success')
              } catch (e) {
                setIsProcessing(false)
                complete('fail')
                throw e
              }
            })



          } else {
            setCanMakePayment(false)
          }
        })
    }
  }, [stripe, intellitagOrder, promoCode, subscriptionPlan])

  const handleSubmit = useCallback(
    async (data) => {
      // console.log(`DATA :: `,data)
      let paymentData = {}

      setIsProcessing(true)

      if (isPaymentRequired) {
        const { zip } = data

        if (!zip || zip === '') {
          setIsProcessing(false)
          throw new Error('Billing ZIP code required')
        }

        const cardNumberElement = elements.getElement(CardNumberElement)

        const { token, error } = await stripe.createToken(cardNumberElement, {
          address_zip: zip,
        })

        if (error) {
          setIsProcessing(false)
          throw error.message
        }

        paymentData = {
          promoCode,
          token: token.id,
        }
      }

      try {
        // console.log(`PLAN ID :: `,breakdown)
        await submitOrder({
          ...intellitagOrder,
          ...paymentData,
          stripeSubscriptionPlan: subscriptionPlan ? breakdown?.subscriptionPlans[subscriptionPlan].id : null,
        })
        setWasSuccessful(true)
      } catch (e) {
        setIsProcessing(false)
        // if(!subscriptionPlan && ){
        //   throw 'Please select plan'
        // }
        throw e
      }
    },
    [breakdown, subscriptionPlan,isPaymentRequired],
  )
  // console.log(`BREAKDOWN :: `,breakdown)
  // console.log(`BREAKDOWN PLAN ID :: `,breakdown?.subscriptionPlans[subscriptionPlan].id,)
  return (
    <Payment
      isPaymentRequired={isPaymentRequired}
      isMobile={isMobile}
      breakdown={breakdown}
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      isLoading={isLoading}
      wasSuccessful={wasSuccessful}
      shippingAddress={intellitagOrder.shippingAddress}
      canMakePayment={canMakePayment}
      paymentRequest={canMakePayment && paymentRequest.current}
      subscriptionPlan={subscriptionPlan}
      onChangeSubscriptionPlan={handleChangeSubscriptionPlan}
      paymentRequestKey={paymentRequestKey}
    />
  )
}

export default connect(
  (state) => ({
    user: state.user,
    intellitagOrder: state.intellitagOrder,
    promoCode: state.promoCode && state.promoCode.code,
  }),
  { submitOrder },
)(PaymentContainer)
