import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import numeral from 'numeral'

import api from 'services/pet-notify-api'
import { completeSignup } from 'UserApp/store/signup/actions'
import SignupPaymentScreen from 'UserApp/screens/Signup/Premium/Payment'

function SignupPaymentScreenContainer(props) {
  const { history, affiliate, completeSignup, signup, promoCode } = props
  const redemptionCode = localStorage.getItem('redemptionCode')
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [breakdown, setBreakdown] = useState()
  const [paymentRequest, setPaymentRequest] = useState()
  const [paymentRequestKey, setPaymentRequestKey] = useState(0)
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    signup.plan || 'month',
  )

  function handleChangeSubscriptionPlan(plan) {
    setSubscriptionPlan(plan)
  }

  useEffect(() => {
    if (!signup.user) {
      history.push('/signup')
      return
    }

    if (signup.intellitags) {
      setIsLoading(true)
      api.signup
        .breakdown({ ...signup, promoCode, subscriptionPlan })
        .then((result) => {
          setBreakdown(result)
          setIsLoading(false)
        })
    }
  }, [promoCode, subscriptionPlan])

  useEffect(() => {
    if (stripe && breakdown) {
      const itemTotal = breakdown?.order.items.find(item => item.label === 'Total');
      console.log('COST::::::',itemTotal.cost)
      const newPaymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Premium Subscription',
          amount: Math.round(itemTotal.cost*100), // We are receiving total as 19.95$ but stripe needs it in cents thats why multiply it with 100
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      newPaymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(newPaymentRequest)
          setPaymentRequestKey((prevKey) => prevKey + 1)
        }
      })

      // Handle token submission when using PaymentRequestButtonElement
      newPaymentRequest.on('token', async ({ complete, token }) => {
        setIsProcessing(true)

        try {
          await completeSignup({
            token: token.id,
            promoCode,
            redemptionCode,
            isAnnualPlan: subscriptionPlan === 'year',
            stripeSubscriptionPlan: breakdown.subscriptionPlans[subscriptionPlan].id,
            policyAcceptance:true,
          })
          setIsProcessing(false)
          history.push('/signup/success?hasIntellitag=true')
        } catch (e) {
          setIsProcessing(false)
          complete('fail')
          throw e
        }
      })
    }
  }, [stripe, breakdown, promoCode, redemptionCode, subscriptionPlan])

  async function handleSubmit(data) {
    const { zip, policyAcceptance, smsPermission } = data

    if (!zip || zip === '') {
      throw new Error('Billing ZIP code required')
    }

    if (!policyAcceptance) {
      throw new Error('Please verify you understand the User Agreement and Privacy Policy')
    }

    if (!smsPermission) {
      throw new Error('Please verify you agree to receive SMS alerts')
    }

    setIsProcessing(true)

    const cardNumberElement = elements.getElement(CardNumberElement)

    const { token, error } = await stripe.createToken(cardNumberElement, {
      address_zip: zip,
    })

    if (error) {
      setIsProcessing(false)
      throw error.message
    }

    try {
      await completeSignup({
        token: token.id,
        promoCode,
        redemptionCode,
        isAnnualPlan: subscriptionPlan === 'year',
        stripeSubscriptionPlan:
          breakdown.subscriptionPlans[subscriptionPlan].id,
        policyAcceptance,
        smsPermission,
      })
      setIsProcessing(false)
    } catch (e) {
      setIsProcessing(false)
      throw e
    }

    history.push('/signup/success?hasIntellitag=true')
  }

  return (
    <SignupPaymentScreen
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      isLoading={isLoading}
      breakdown={breakdown}
      signup={signup}
      affiliate={affiliate}
      subscriptionPlan={subscriptionPlan}
      onChangeSubscriptionPlan={handleChangeSubscriptionPlan}
      paymentRequest={paymentRequest}
      paymentRequestKey={paymentRequestKey}
    />
  )
}

export default connect(
  (state) => ({
    signup: state.signup,
    promoCode: state.promoCode && state.promoCode.code,
    affiliate: state.promoCode && state.promoCode.affiliate,
  }),
  { completeSignup },
)(SignupPaymentScreenContainer)
